import React, { useMemo, useEffect } from "react";
import find from "lodash/find";
import sortBy from "lodash/sortBy";

import { useProductDetails } from "../../../hooks/useProductDetails";
import { useAppState } from "../../../state";
import configActions from "../../../state/configuration/actions";
import summaryActions from "../../../state/summary/actions";
import { removeDecimalIfWhole } from "../../../utils/removeDecimalIfWhole";

import { ControlPanelCard } from "../control-panel-card";
import { CardButtonBase } from "../common/card/CardButtonBase";
import { Select } from "../common/select";
import { SkeletonCard } from "../common/skeleton-card";
import InfoIcon from "jsx:../../../images/icon-info.inline.svg";

const images = {
  interworx: new URL(
    "../../../images/control-panel/interworx.png?width=124",
    import.meta.url,
  ),
  plesk: new URL(
    "../../../images/control-panel/plesk.png?height=32",
    import.meta.url,
  ),
  cpanel: new URL(
    "../../../images/control-panel/cpanel.png?height=24",
    import.meta.url,
  ),
};

const NOCP_PANEL_ORDER = {
  interworx: null,
  cpanel: null,
  plesk: null,
  nocp: null,
};
const PANEL_ORDER = { interworx: null, cpanel: null, plesk: null };
const NOCP_PANEL_ORDER_WIN = { plesk: null, nocp: null };
const PANEL_ORDER_WIN = { plesk: null };

export const IWORX_MIN_MEMORY_GB = 3;

export function SectionControlPanel() {
  const [{ configuration: state }, dispatch] = useAppState();
  const productData = useProductDetails();
  const { status, data } = productData[state.productCode];
  const showSkeleton =
    state.isLoading ||
    state.isError === "api-fetch" ||
    state.isError === "management";

  const getAvailableControlPanels = (distro, version) => {
    const chosenDistro =
      distro && state.availableDistros?.[distro]
        ? state.availableDistros[distro]
        : [];
    const match = find(chosenDistro, { version: version, name: distro });

    return match?.control_panels ? match.control_panels : [];
  };

  const getControlPanelOrder = (hasNoCP, os) => {
    if (os === "windows") {
      return hasNoCP ? NOCP_PANEL_ORDER_WIN : PANEL_ORDER_WIN;
    } else {
      return hasNoCP ? NOCP_PANEL_ORDER : PANEL_ORDER;
    }
  };

  const controlPanelOptions = useMemo(() => {
    if (status === "success" && data?.control_panels) {
      const basePanels = data.control_panels.map((panel) => ({
        id: panel.type.toLowerCase(),
        name: panel?.type,
        cost: null,
        billingCycle: "Monthly",
        template: null,
        disabled: true,
      }));
      const availablePanels = getAvailableControlPanels(
        state.operatingSystemDistro,
        state.operatingSystemVersion,
      );
      
      const hasNoCP = find(availablePanels, { name: "NoCP" });
      const panelsObject = {};

      basePanels &&
        basePanels.forEach((base) => {
          const availablePanel = find(availablePanels, { name: base.name });

          if (base.id === "nocp" && !hasNoCP) {
            return;
          }

          panelsObject[base.id] = {
            ...base,
            default: availablePanel?.default ? availablePanel.default : false,
            disabled: !availablePanel,
            cost: availablePanel?.price
              ? removeDecimalIfWhole(getMonthlyCost(availablePanel.price))
              : null,
            template: availablePanel?.template ? availablePanel.template : null,
            options: availablePanel?.included_options
              ? availablePanel.included_options
              : [],
          };
        });

      const panelOrder = getControlPanelOrder(
        !!hasNoCP,
        state.operatingSystemType,
      );
      const finalPanels = Object.assign(panelOrder, panelsObject);
      const defaultPanel = find(finalPanels, { default: 1 });

      // Check for CP options that are not 'NoCP' and that have a cost
      const hasControlPanels = find(finalPanels, (panel) => {
        return (panel?.cost && panel?.cost !== null) && (panel?.id && panel.id !== "nocp");
      });

      return {
        defaultPanel: defaultPanel?.name ? defaultPanel.name : "",
        defaultTemplate: defaultPanel?.template ? defaultPanel.template : "",
        showPanels: !!hasControlPanels,
        panels: finalPanels,
      };
    }

    return {
      defaultPanel: "",
      defaultTemplate: "",
      showPanels: false,
      panels: [],
    };
  }, [status, data, state.templateValue, state.operatingSystemTab]);

  useEffect(() => {
    const cPanelLicenseOptions = find(
      controlPanelOptions?.panels?.cpanel?.options,
      { key: "cPanelLicenseTier" },
    );

    if (cPanelLicenseOptions) {
      const defaultLicense = find(cPanelLicenseOptions.values, { default: 1 });
      dispatch(
        configActions.setCpanelLicenseTier(
          defaultLicense?.value ? defaultLicense.value : "",
        ),
      );
    }
  }, [controlPanelOptions, dispatch]);

  useEffect(() => {
    if (state.templateValue) {
      const currentPanel = find(controlPanelOptions.panels, {
        template: state.templateValue,
      });

      if (!currentPanel) {
        return;
      }

      const { id, name, cost, options } = currentPanel;
      const antiVirus = find(options, { key: "WinAV" });

      dispatch(
        configActions.setAvailableAntiVirus(
          antiVirus?.values ? antiVirus.values : [],
        ),
      );

      dispatch(
        summaryActions.setControlPanel({
          value: name === "NoCP" ? "No Control Panel" : name,
          cost: Number(cost),
        }),
      );

      if (id !== "cpanel") {
        dispatch(
          summaryActions.setControlPanelLicense({
            value: "",
            cost: 0,
          }),
        );
      }
    }
  }, [state.templateValue]);

  function getMonthlyCost(priceArray = []) {
    const priceObject = find(priceArray, { unit: "month" });

    return priceObject?.amount ? priceObject.amount : "";
  }

  function getPanelLicenseCost(priceArray = []) {
    const price = removeDecimalIfWhole(getMonthlyCost(priceArray));

    return price === "0" ? "" : ` (+$${price} Monthly)`;
  }

  function getControlPanelCost(price) {
    if (!price) {
      return "";
    }

    return price === "0" ? "No Cost" : `+$${price} Monthly`;
  }

  function handleControlPanelSelection(panel, cPanelOptions) {
    if (panel.id === "cpanel") {
      const activeCpanelOption = find(cPanelOptions, {
        value: state.cPanelLicenseTier,
      });
      const cost = getMonthlyCost(activeCpanelOption?.price);

      dispatch(
        summaryActions.setControlPanelLicense({
          value: activeCpanelOption?.description
            ? activeCpanelOption.description
            : "",
          cost: Number(cost),
        }),
      );
    }

    if (panel.id !== "Interworx") {
      dispatch(configActions.toggleImplicitConfigUpdateNotice(false));
    }

    dispatch(configActions.setControlPanel(panel.name));
    dispatch(configActions.setTemplateValue(panel.template));
  }

  function handleCpanelLicenseChange(option, cPanelOptions) {
    const activeCpanelOption = find(cPanelOptions, { value: option });
    const cost = getMonthlyCost(activeCpanelOption?.price);

    dispatch(
      summaryActions.setControlPanelLicense({
        value: activeCpanelOption?.description
          ? activeCpanelOption.description
          : "",
        cost: Number(cost),
      }),
    );

    dispatch(configActions.setCpanelLicenseTier(option));
  }

  if (state.isBareMetal || !controlPanelOptions.showPanels) {
    return null;
  }

  return (
    <div>
      <h3 className="text-xl font-normal mt-0 mb-2">Control Panel</h3>
      <p className="mb-6">
        Interface options to streamline and manage server operations.
      </p>

      {state.showImplicitConfigUpdateNotice && (
        <div className="mb-10 flex flex-col gap-3">
          <div className="p-3 bg-lw-ui-light text-sm flex gap-3 rounded">
            <div className="text-black basis-4 shrink-0 grow-0 pt-[2px]">
              <InfoIcon width="16" height="16" />
            </div>
            <div>
              InterWorx requires at least {IWORX_MIN_MEMORY_GB} GB of memory.
              We&apos;ve updated your hardware selection to ensure enough memory
              is available to deploy your server.
            </div>
          </div>
        </div>
      )}

      <div className="grid grid-cols-1 xl:grid-cols-3 gap-2 lg:gap-4">
        {showSkeleton
          ? Array(3)
              .fill()
              .map((_, index) => (
                <SkeletonCard key={index} className="h-[66px] lg:h-[105px]" />
              ))
          : null}
        {!state.isLoading &&
          (state.isError === "" || state.isError === "api-post") &&
          controlPanelOptions &&
          controlPanelOptions?.panels &&
          Object.keys(controlPanelOptions.panels).map((id) => {
            const panel = controlPanelOptions.panels[id];
            const panelCost = getControlPanelCost(panel.cost);
            const panelCount = Object.keys(controlPanelOptions.panels).length;
            const cPanelLiscenses = find(panel.options, {
              key: "cPanelLicenseTier",
            });
            const cPanelOptions =
              state.operatingSystemType === "linux" && cPanelLiscenses?.values
                ? sortBy(cPanelLiscenses.values, ["display_order"])
                : [];

            if (!panel) {
              return;
            }

            return panel?.id === "nocp" ? (
              <CardButtonBase
                key={panel.id}
                className="col-span-full justify-center min-h-[66px]"
                disabled={panel.disabled}
                isSelected={state.templateValue === panel.template}
                onClick={() =>
                  handleControlPanelSelection(panel, cPanelOptions)
                }
              >
                No Control Panel
              </CardButtonBase>
            ) : (
              <ControlPanelCard
                className={panelCount <= 2 ? "col-span-full" : ""}
                orientation={panelCount <= 2 ? "horizontal" : "vertical"}
                key={panel.id}
                isSelected={state.templateValue === panel.template}
                disabled={panel.disabled}
                onClick={() =>
                  handleControlPanelSelection(panel, cPanelOptions)
                }
                logo={
                  panel.id && images[panel.id] ? (
                    <img src={images[panel.id]} alt={`${panel.id} Logo`} />
                  ) : null
                }
                {...panel}
                cost={panelCost}
              >
                {cPanelOptions?.length ? (
                  <Select
                    label={<label className="text-sm">License</label>}
                    controlElemClass="w-full mt-6"
                    size="sm"
                    value={state.cPanelLicenseTier}
                    onChange={(e) =>
                      handleCpanelLicenseChange(
                        e.target.value,
                        cPanelOptions,
                        panel,
                      )
                    }
                  >
                    {cPanelOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {`${option.description}${getPanelLicenseCost(option.price)}`}
                      </option>
                    ))}
                  </Select>
                ) : null}
              </ControlPanelCard>
            );
          })}
      </div>
    </div>
  );
}
